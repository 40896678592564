@charset "UTF-8";
@import url(reset.css);
/*
  Typography
  Font, line-height, and color for body text, headings, and more.
*/
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,800,600&subset=latin,cyrillic);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700,300);
@import url(font-awesome.min.css);
html {
  font-size: 10px; }

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  margin: 0;
  font-family: 'Open Sans Condensed', sans-serif; }
  body .main-wrapper {
    flex: 1; }

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto; }

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

html {
  font-size: 67.5%; }

body {
  font-family: 'Open Sans', sans-serif;
  color: #fff; }

a {
  text-decoration: none;
  color: #fff; }

a:hover {
  color: #fa2e61; }

.wrapper {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 25px 0; }

.clearfix:after {
  content: '';
  clear: both;
  display: table; }

.owl-carousel.owl-theme .owl-nav [class*=owl-] {
  position: absolute;
  top: calc(40% - 30px);
  width: 40px;
  height: 40px;
  border: 2px solid #fff;
  border-radius: 50%;
  background: transparent; }
  .owl-carousel.owl-theme .owl-nav [class*=owl-] .chevron {
    width: 30px;
    height: 30px;
    background-image: url(../img/shevron.svg);
    background-repeat: no-repeat;
    background-size: 23px; }

.owl-carousel .owl-next {
  right: 0; }
  .owl-carousel .owl-next .chevron {
    transform: rotate(-90deg);
    background-position: 4px 2px; }

.owl-carousel .owl-prev {
  left: 0; }
  .owl-carousel .owl-prev .chevron {
    transform: rotate(90deg);
    background-position: 3px 9px; }

@media (max-width: 768px) {
  .owl-carousel.owl-theme .owl-nav [class*=owl-] {
    top: calc(30% - 30px); } }

.modal-dialog {
  max-width: 700px; }

.modal-content {
  position: relative; }

.modal-header .close {
  font-size: 60px;
  font-weight: 400;
  padding: 0 1rem 1rem 1rem; }

.modal-content:before {
  position: absolute;
  background-image: url(../img/main_bg.jpg);
  -webkit-background-size: cover;
  background-size: cover;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  padding: 45px 96px; }

.modal-different-content {
  display: none; }

.popup-img-container {
  width: 185px;
  height: 185px;
  background-size: cover;
  border-radius: 50%;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 6px solid #fff; }

.modal-title {
  font-family: 'Roboto', sans-serif;
  font-size: 26px;
  color: #000;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 1.4;
  text-transform: uppercase; }

.modal-desc {
  color: #303030;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 20px; }
  .modal-desc strong {
    font-weight: 600; }

.modal-body .modal-desc {
  margin-top: 15px;
  text-align: left; }

.modal-footer {
  margin-bottom: 20px; }

.modal-footer .modal-desc {
  margin-top: 20px; }

.modal-footer .callback_form .button {
  font-family: 'Roboto', sans-serif;
  border: 0;
  width: 255px;
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  background: #fa2e61;
  padding: 15px 15px;
  transition: ease 0.5s;
  text-align: center; }
  .modal-footer .callback_form .button:hover {
    background: #3dbce2; }

.btn {
  display: inline-block;
  border-radius: 4px;
  font-size: 15px;
  text-transform: uppercase;
  background: #fa2e61;
  padding: 15px 20px;
  cursor: pointer;
  transition: ease 0.5s; }

.btn:hover {
  background: #3dbce2; }

header {
  max-width: 100%;
  font-size: 14px; }

.mobile-menu-button {
  display: none; }

.mobile-menu {
  display: none; }

header .wrapper {
  position: relative; }

header nav .wrapper, header .info .wrapper, header .call_order .wrapper {
  padding: 0; }

header .logo {
  background: url(../img/logo_bg.png);
  text-align: center;
  height: 170px;
  width: 160px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10; }

header .responsive-logo {
  display: none; }

header .logo img {
  padding-top: 35px; }

header .logo h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  color: #3dbce2;
  font-weight: 700;
  padding-top: 10px;
  line-height: 1.4; }

header .logo p {
  padding: 20px 15px 0;
  font-weight: 500;
  color: #000;
  font-size: 13px; }

header nav {
  font-weight: 300;
  min-height: 45px;
  max-width: 100%;
  background: #3dbce2; }

header nav ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 160px;
  height: 45px; }

header nav ul li {
  flex: 1 0 auto;
  text-align: center; }

header nav ul li a {
  display: block;
  padding: 15px 19px;
  height: 45px;
  text-decoration: none !important; }

header nav ul li a:hover {
  color: white; }

header nav ul li:nth-child(-n+6) {
  border-right: 1px rgba(255, 255, 255, 0.2) solid; }

header nav ul li a:hover {
  background: #37a9cb;
  padding: 15px 19px;
  transition: ease 0.3s;
  cursor: pointer; }

header nav .menu_responsive {
  display: none; }

header .info {
  max-height: 85px;
  max-width: 100%;
  background: #fff; }

header .info .margin {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: 160px;
  max-height: 85px; }

header .info i {
  color: #ef404a; }

header .info h2 {
  color: #2f2f2f;
  text-transform: uppercase;
  font-size: 22px;
  padding-bottom: 10px; }

header .info a {
  color: #ef404a;
  text-decoration: none; }

header .info .info-inline .adress {
  font-size: 22px;
  margin-bottom: 10px; }

header .info .info-inline .middle {
  margin-bottom: 10px; }

header .info .info-inline .adress {
  color: #2f2f2f;
  text-transform: uppercase;
  font-weight: 200; }

header .info .info-inline .adress a:hover {
  border: 0; }

header .info .info-inline {
  display: inline-block;
  vertical-align: top;
  font-weight: 300;
  margin-top: 0;
  padding: 10px 25px; }

header .info .info-inline p {
  text-align: center;
  color: #000;
  font-weight: 500; }

header .info .info-inline:nth-child(-n+2) {
  border-right: 1px solid #e5e5e5; }

header .info .info-inline span {
  color: #ef404a;
  border-bottom: 1px dashed #ef404a;
  font-weight: 500;
  padding: 0 4px; }

header .info .info-inline a:hover {
  color: #ff747c;
  border-bottom: 1px dashed #ff747c; }

header .info .info-inline a.vk {
  display: block;
  border-radius: 4px;
  font-size: 15px;
  background: #fa2e61;
  padding: 15px 20px;
  transition: ease 0.5s; }

header .info .info-inline a.vk:hover {
  background: #3dbce2; }

header .info .info-inline .button p i {
  margin-top: -5px;
  font-size: 25px;
  vertical-align: middle;
  color: #fff; }

header .info .info-inline .button a {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  border: 0;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2); }

header .call_order {
  background: url(../img/main_bg.jpg);
  background-attachment: fixed;
  background-size: cover;
  min-height: 650px;
  max-width: 100%;
  text-align: center;
  padding: 120px 15px 0;
  position: relative; }
  header .call_order:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); }

header .call_order .text-wrap {
  margin-bottom: 100px; }

header .call_order h1 {
  font-weight: 800;
  font-size: 50px;
  margin-bottom: 20px;
  line-height: 1.5;
  text-shadow: black 0 0 16px; }

header .call_order p {
  line-height: 1.3;
  font-weight: 400;
  font-size: 24px;
  text-shadow: black 0 0 24px; }

header .call_order .callback_form {
  max-width: 800px;
  margin: 0 auto; }

.callback_form .button {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 18px;
  display: block;
  width: 280px;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  -webkit-transform: translatez(0);
  transform: translatez(0);
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase; }

.sweepButton {
  border-radius: 50px;
  padding: 20px 26px;
  color: #f0f0f0;
  background: #f62459;
  -webkit-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
  animation: border 1.8s infinite ease-out; }
  .sweepButton:hover {
    color: #f0f0f0;
    -webkit-box-shadow: 0 0 85px -5px #f62459;
    -moz-box-shadow: 0 0 85px -5px #f62459;
    box-shadow: 0 0 85px -5px #f62459; }

.sweepButton .gradient {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 300%;
  height: 100%;
  bottom: auto;
  margin: auto;
  z-index: -1;
  background: #f62459;
  background: -moz-linear-gradient(90deg, #f62459 0%, #3dbce2 94%, #f62459 100%);
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #f62459), color-stop(94%, #3dbce2), color-stop(100%, #f62459));
  background: -webkit-linear-gradient(90deg, #f62459 0%, #3dbce2 94%, #f62459 100%);
  background: -o-linear-gradient(90deg, #f62459 0%, #3dbce2 94%, #f62459 100%);
  background: -ms-linear-gradient(90deg, #f62459 0%, #3dbce2 94%, #f62459 100%);
  background: linear-gradient(90deg, #f62459 0%, #3dbce2 94%, #f62459 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f62459', endColorstr='#f62459',GradientType=1 );
  -webkit-transition: all 0s ease-out 0s;
  transition: all 0s ease-out 0s;
  -webkit-transform: translateX(-35%);
  -moz-transform: translateX(-35%);
  transform: translateX(-35%);
  animation: gradient 1.8s infinite ease-out; }

@keyframes gradient {
  0% {
    -webkit-transform: translateX(-35%);
    -moz-transform: translateX(-35%);
    transform: translateX(-35%); }
  100% {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    transform: translateX(100%); } }

header .call_order .callback_form p {
  color: #fff;
  font-size: 1rem; }

section {
  text-align: center;
  color: #303030;
  max-width: 100%; }

section p, footer p {
  font-size: 1.4rem; }

section h3 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 3.1rem;
  text-transform: uppercase;
  padding: 40px 0 25px; }

section h4 {
  font-family: 'Roboto', sans-serif;
  color: #fff;
  font-weight: 400;
  font-size: 2.5rem;
  text-transform: uppercase;
  padding: 30px 0 20px; }

section.popular-services {
  padding-bottom: 50px; }

section.popular-services .services {
  padding-top: 25px; }

section.popular-services .services .row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  section.popular-services .services .row .item {
    flex: 1;
    flex-basis: 50%;
    margin-bottom: 20px; }
  section.popular-services .services .row .item-header {
    font-family: 'Roboto', sans-serif;
    color: #000;
    font-size: 2.1rem;
    font-weight: 400;
    text-align: left;
    padding: 0 0 15px; }
  section.popular-services .services .row .item-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0 25px 25px; }
    section.popular-services .services .row .item-content .item-logo {
      width: 200px;
      height: 200px;
      border-radius: 50%;
      overflow: hidden;
      flex-shrink: 0;
      border: 8px solid #f62459; }
      section.popular-services .services .row .item-content .item-logo img {
        width: 100%;
        height: auto; }
    section.popular-services .services .row .item-content .item-services-list {
      margin-left: 20px; }
    section.popular-services .services .row .item-content .item-services-list ul li {
      position: relative;
      padding-left: 20px;
      text-align: left;
      margin-bottom: 8px; }
      section.popular-services .services .row .item-content .item-services-list ul li:before {
        position: absolute;
        left: 5px;
        top: calc(50% - 3px);
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background-color: #f62459;
        transition: .5s ease; }
      section.popular-services .services .row .item-content .item-services-list ul li:hover a {
        color: #3dbce2; }
      section.popular-services .services .row .item-content .item-services-list ul li:hover:before {
        background-color: #3dbce2; }
    section.popular-services .services .row .item-content a {
      font-size: 1.6rem;
      color: #f62459;
      line-height: 1.2;
      text-decoration: none;
      transition: .5s ease;
      outline: none; }

@media (max-width: 768px) {
  section.popular-services .services .row .item-content {
    flex-direction: column; } }

section.reviews {
  position: relative;
  background: url(../img/reviews.jpg) fixed;
  background-size: cover;
  min-height: 500px;
  max-width: 100%;
  text-shadow: -1px 1px 2px #000; }
  section.reviews:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); }
  section.reviews h3 {
    position: relative;
    z-index: 999; }

section.reviews h3, h5, p, a {
  color: #fff; }

section.reviews h5 {
  font-size: 25px;
  font-weight: 600;
  padding-bottom: 20px; }

section.reviews p {
  text-align: justify;
  line-height: 1.5; }

section.reviews .about_text {
  max-width: 100%; }

section.reviews .left {
  margin: 20px;
  min-height: 200px;
  width: 200px; }

section.reviews .right {
  max-width: 700px;
  margin-top: -50px; }

section.reviews .layout .row {
  display: inline-block;
  vertical-align: middle; }

section.reviews .left img {
  border-radius: 50%;
  border: 6px #fff solid; }

section.reviews .left p {
  text-align: center;
  padding: 20px;
  font-size: 22px;
  font-weight: 600; }

section.reviews a {
  font-size: 15px; }

section.reviews blockquote {
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  line-height: 1.3;
  font-size: 18px;
  font-weight: 600;
  font-style: italic;
  margin: 1.9em 10px;
  padding: 20px;
  position: relative; }

section.reviews blockquote:before {
  font-family: Georgia, "Times New Roman", Times, serif;
  color: #fff;
  content: "\201C";
  /* Непечатаемый символ слева */
  font-size: 3em;
  line-height: 0.1em;
  margin-right: 0.2em;
  vertical-align: -.3em; }

section.reviews blockquote:after {
  font-family: Georgia, "Times New Roman", Times, serif;
  color: #fff;
  content: "\201D";
  /* Непечатаемый символ справа */
  font-size: 3em;
  line-height: 0.1em;
  vertical-align: -.7em; }

section.reviews blockquote > p:first-child {
  display: inline; }

section.cosmetics {
  max-width: 100%;
  padding-bottom: 80px; }
  section.cosmetics img {
    width: 100%;
    height: auto; }
  section.cosmetics .cosmetics-mobile {
    display: none; }

section.advantages {
  position: relative;
  background: url(../img/advantages.jpg) fixed;
  background-size: cover;
  max-width: 100%;
  color: #fff;
  text-shadow: -1px 1px 2px #000;
  padding-bottom: 30px; }
  section.advantages:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); }

section.advantages .advantages-list {
  text-align: left;
  font-size: 26px;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 50px; }
  section.advantages .advantages-list .advantages-list-item {
    margin-bottom: 10px;
    position: relative;
    padding-left: 25px; }
    section.advantages .advantages-list .advantages-list-item:before {
      position: absolute;
      content: '';
      top: 2px;
      left: 0;
      width: 18px;
      height: 18px;
      border: 1px solid white; }
    section.advantages .advantages-list .advantages-list-item:after {
      position: absolute;
      content: '';
      background-image: url(../img/checkbox-okay.svg);
      background-repeat: no-repeat;
      background-position: center;
      -webkit-background-size: 17px 17px;
      background-size: 17px 17px;
      width: 17px;
      height: 17px;
      top: 3px;
      left: 1px; }

section.advantages h4 {
  margin-top: 40px;
  font-size: 26px;
  font-weight: 600; }

section.advantages p {
  font-size: 26px;
  font-weight: 600; }

section.advantages .callback_form {
  margin-top: 40px; }

section.map {
  min-height: 300px;
  max-width: 99.9%; }

footer .copyright {
  background: #303030;
  color: #fff;
  min-height: 100px;
  max-width: 100%;
  text-align: center;
  padding: 40px; }

footer a {
  color: #fa2e61;
  transition: ease 0.5s; }

footer a:hover {
  color: #3dbce2; }

footer .love {
  background: #fff;
  font-weight: 600;
  min-height: 50px;
  max-width: 100%;
  text-align: center;
  padding: 20px; }

footer .love p {
  color: #303030; }

footer .love i {
  color: #fa2e61; }

footer .footer_agreement {
  font-size: 12px; }

/* Back to top button */
.cd-top {
  display: inline-block;
  height: 40px;
  width: 40px;
  position: fixed;
  bottom: 20px;
  left: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  /* image replacement properties */
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  background: rgba(61, 188, 226, 0.8) url(../img/cd-top-arrow.svg) no-repeat center 50%;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity .3s 0s, visibility 0s .3s;
  -moz-transition: opacity .3s 0s, visibility 0s .3s;
  transition: opacity .3s 0s, visibility 0s .3s;
  z-index: 999; }

.cd-top.cd-is-visible, .cd-top.cd-fade-out, .no-touch .cd-top:hover {
  -webkit-transition: opacity .3s 0s, visibility 0s 0s;
  -moz-transition: opacity .3s 0s, visibility 0s 0s;
  transition: opacity .3s 0s, visibility 0s 0s; }

.cd-top.cd-is-visible {
  /* the button becomes visible */
  visibility: visible;
  opacity: 1; }

.cd-top.cd-fade-out {
  /* if the user keeps scrolling down, the button is out of focus and becomes less visible */
  opacity: .5; }

.cd-top:hover {
  background-color: #fa2e61;
  opacity: 1; }

@media only screen and (min-width: 768px) {
  .cd-top {
    left: 20px;
    bottom: 20px; } }

@media only screen and (min-width: 1024px) {
  .cd-top {
    height: 60px;
    width: 60px;
    left: 30px;
    bottom: 30px; } }

@media screen and (min-width: 500px) and (max-width: 1030px) {
  header .logo {
    display: none; }
  header .info .info-inline {
    display: block; }
  header .info .margin {
    margin-left: 0;
    min-height: 235px;
    justify-content: space-between; }
  header nav ul li {
    float: none;
    display: block;
    text-align: center;
    border-bottom: 1px solid white; }
  header nav ul {
    margin-left: 0; }
  header .call_order .callback_form .field {
    display: block;
    margin: 0 auto; }
  header .call_order .callback_form .field:nth-child(2) {
    margin: 15px auto; }
  section .health .health_logo {
    float: none;
    margin: 0 auto;
    padding: 20px 0px 40px; }
  section .health .health_text {
    text-align: center;
    padding: 40px 0px 25px 0px;
    max-width: 100%; }
  section .manikur .manikur_text {
    text-align: center;
    padding: 20px 0px 25px 0px;
    max-width: 100%; }
  section .manikur .manikur_logo {
    float: none;
    margin: 0 auto;
    padding: 40px 0px 60px; }
  section .manikur .price {
    top: 400px;
    left: 250px; }
  section .hair_master .hair_master_logo {
    float: none;
    margin: 0 auto;
    padding-top: 20px;
    max-width: 100%; } }

@media screen and (max-width: 768px) {
  .hidden-mobile {
    display: none; }
  section h3 {
    font-size: 2.5rem;
    line-height: 1.5; }
  header .logo {
    display: none; }
  header .responsive-logo {
    background: #fff;
    display: block;
    padding: 15px 0 0;
    text-align: center; }
  header nav {
    background: #fff;
    border-bottom: 1px solid #fa2e61; }
  header .navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 15px; }
  header .navigation .responsive-logo img {
    max-height: 40px;
    width: auto; }
  header .info .margin {
    margin-left: 0;
    max-height: none; }
  header .info {
    display: none;
    max-height: none; }
  header .info.info-mobile {
    display: block;
    margin-top: 10px;
    padding-bottom: 15px; }
  header .info .info-inline {
    display: block;
    width: 100%; }
  header .info .info-inline:nth-child(-n+2) {
    border: none; }
  header nav ul li {
    float: none;
    display: block;
    text-align: center;
    border-bottom: 1px solid white; }
  header nav ul {
    border-bottom: 1px solid #000;
    margin-left: 0; }
  header .call_order {
    min-height: auto;
    padding: 60px 15px 100px; }
  header .call_order h1 {
    font-size: 30px;
    line-height: 1.4; }
  header .call_order p {
    font-size: 18px; }
  header .call_order .text-wrap {
    margin-bottom: 60px; }
  section.reviews {
    min-height: auto; }
  section.popular-services .services .row .item-content .item-logo {
    margin: 0 auto 30px;
    width: 80%;
    height: auto; }
  section.popular-services .services .row .item-content .item-services-list {
    margin: 0; }
    section.popular-services .services .row .item-content .item-services-list h4 {
      text-align: center; }
  .popup-img-container {
    margin: 0 auto; }
  section.cosmetics .cosmetics-mobile {
    display: block; }
  section.cosmetics .cosmetics-desktop {
    display: none; }
  section.cosmetics {
    padding-bottom: 30px; }
  section.advantages:before {
    background-color: rgba(0, 0, 0, 0.7); }
  section.advantages .advantages-list {
    font-size: 18px; }
  section.advantages h4,
  section.advantages .advantages-list {
    margin-top: 0; }
  section.advantages h4, section.advantages p {
    font-size: 18px; }
  section.advantages .advantages-list {
    margin-bottom: 20px; }
  section.advantages {
    padding-bottom: 40px; }
  section.advantages .callback_form {
    margin-bottom: 40px; } }

@media (max-width: 768px) {
  #navigation-menu {
    display: none; }
  .mobile-menu-button {
    display: block;
    background-color: transparent;
    border: none;
    height: 70px;
    width: 58px;
    padding: 15px 0 15px 15px; }
    .mobile-menu-button:focus {
      outline: none; }
    .mobile-menu-button svg {
      width: 47px;
      height: 55px; }
      .mobile-menu-button svg path {
        fill: #fa2e61; }
  .mobile-menu {
    position: absolute;
    top: 70px;
    right: 0;
    left: 0;
    background-color: #fff;
    z-index: 999; }
    .mobile-menu ul {
      flex-direction: column;
      height: auto;
      list-style: none;
      padding: 0;
      border: none; }
      .mobile-menu ul li {
        display: block;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        text-decoration: none;
        background-color: #fa2e61;
        border-bottom: 1px solid #fff; }
        .mobile-menu ul li:nth-child(-n+6) {
          border-right: none; }
        .mobile-menu ul li:last-child {
          border: none; }
        .mobile-menu ul li .mobile-link {
          display: block;
          width: 100%;
          height: 100%;
          color: #fff;
          padding: 15px; } }
